import React from 'react';
import Link from "next/link";
import AppStoreButton from "../buttons/AppStoreButton";
import PlayMarketButton from "../buttons/PlayMarketButton";

const CoinFooter = ({t}) => {

    const footer = t('coin:footer', {}, {returnObjects: true});

    const BottomItem = ({item}) => {
        return <li className='list_item'>{item.title}
            <Link
                scroll={item.url === "/terms-and-conditions" || item.url === "/privacy-policy"}
                className='list_item_link'
                prefetch={false}
                target={item.target}
                href={item.url}
            >
                {item.link}
            </Link>
        </li>
    }

    return (
        <footer className='coin_footer'>
            <div className="container">
                <section className='coin_footer_top'>
                    <Link
                        className='nav_logo'
                        prefetch={false}
                        href={'/'}
                    >
                        <svg width="170" height="32" viewBox="0 0 170 32" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.91 15.978C0.91 10.726 5.226 6.592 10.582 6.592C15.964 6.592 20.254 10.726 20.254 15.978C20.254 21.256 15.964 25.364 10.582 25.364C5.226 25.364 0.91 21.256 0.91 15.978ZM4.914 15.978C4.914 19.228 7.384 21.724 10.582 21.724C13.754 21.724 16.25 19.176 16.25 15.978C16.25 12.832 13.78 10.232 10.582 10.232C7.384 10.232 4.914 12.806 4.914 15.978ZM22.4758 30.018V11.896H26.3498V13.612C26.7138 12.494 28.2478 11.662 30.2498 11.662C33.9678 11.662 36.7498 14.652 36.7498 18.448C36.7498 22.218 33.9678 25.234 30.2498 25.234C28.2478 25.234 26.7138 24.428 26.3498 23.284V30.018H22.4758ZM26.1158 18.448C26.1158 20.294 27.6498 21.724 29.5478 21.724C31.4198 21.724 32.8758 20.294 32.8758 18.448C32.8758 16.602 31.4198 15.172 29.5478 15.172C27.6498 15.172 26.1158 16.602 26.1158 18.448ZM39.6092 25V15.406H37.8672V11.896H39.6092V6.878H43.3792V11.896H46.2652V15.406H43.3792V25H39.6092ZM49.8666 10.778C48.6706 10.778 47.6566 9.816 47.6566 8.594C47.6566 7.346 48.6706 6.384 49.8666 6.384C51.0886 6.384 52.0766 7.346 52.0766 8.594C52.0766 9.816 51.0886 10.778 49.8666 10.778ZM47.9426 25V11.896H51.8166V25H47.9426ZM53.8452 18.448C53.8452 14.574 57.0692 11.506 61.1252 11.506C65.1552 11.506 68.4052 14.574 68.4052 18.448C68.4052 22.322 65.1552 25.39 61.1252 25.39C57.0692 25.39 53.8452 22.322 53.8452 18.448ZM57.7192 18.448C57.7192 20.372 59.2012 21.88 61.1252 21.88C63.0232 21.88 64.5312 20.372 64.5312 18.448C64.5312 16.55 63.0232 15.016 61.1252 15.016C59.2012 15.016 57.7192 16.55 57.7192 18.448ZM70.4387 25V11.896H74.3127V13.534C74.8587 12.39 76.3147 11.61 78.1347 11.61C81.3587 11.61 83.1527 13.664 83.1527 17.044V25H79.2787V17.512C79.2787 16.004 78.4467 15.042 76.8867 15.042C75.4047 15.042 74.3127 16.108 74.3127 17.642V25H70.4387Z"
                                fill="#191919"/>
                            <path
                                d="M83.818 25L92.242 14.626C92.814 13.924 93.204 13.196 93.204 12.39C93.204 11.194 92.294 10.232 91.124 10.232C89.876 10.232 88.862 11.428 88.862 12.91H84.858C84.858 9.4 87.666 6.592 91.124 6.592C94.478 6.592 97.208 9.192 97.208 12.39C97.208 13.378 97.104 14.678 95.804 16.238L91.566 21.36H97.728V25H83.818Z"
                                fill="#191919"/>
                            <path
                                d="M104.252 25V10.518H99.338V6.878H113.092V10.518H108.256V25H104.252ZM113.681 25V11.896H117.555V13.69C118.153 12.364 119.557 11.61 121.351 11.61C121.715 11.61 122.157 11.662 122.365 11.688V15.328C122.001 15.25 121.559 15.198 121.065 15.198C118.855 15.198 117.555 16.472 117.555 18.604V25H113.681ZM123.087 18.448C123.087 14.678 125.869 11.662 129.587 11.662C131.589 11.662 133.123 12.468 133.487 13.612V11.896H137.361V25H133.487V23.284C133.123 24.402 131.589 25.234 129.587 25.234C125.869 25.234 123.087 22.244 123.087 18.448ZM126.961 18.448C126.961 20.294 128.417 21.724 130.289 21.724C132.187 21.724 133.721 20.294 133.721 18.448C133.721 16.602 132.187 15.172 130.289 15.172C128.417 15.172 126.961 16.602 126.961 18.448ZM139.363 18.448C139.363 14.678 142.145 11.662 145.863 11.662C147.865 11.662 149.399 12.468 149.763 13.612V6.878H153.637V25H149.763V23.284C149.399 24.402 147.865 25.234 145.863 25.234C142.145 25.234 139.363 22.244 139.363 18.448ZM143.237 18.448C143.237 20.294 144.693 21.724 146.565 21.724C148.463 21.724 149.997 20.294 149.997 18.448C149.997 16.602 148.463 15.172 146.565 15.172C144.693 15.172 143.237 16.602 143.237 18.448ZM155.638 18.552V18.422C155.638 14.262 158.602 11.584 162.58 11.584C166.584 11.584 169.21 14.548 169.21 18.214C169.21 18.214 169.21 18.89 169.132 19.488H159.512C159.59 21.048 160.916 22.036 162.97 22.036C165.466 22.036 166.87 21.282 167.624 20.762V24.142C166.402 24.922 165.05 25.338 162.762 25.338C158.524 25.338 155.638 22.66 155.638 18.552ZM159.538 16.966H165.31C165.31 15.796 164.114 14.808 162.58 14.808C160.968 14.808 159.616 15.77 159.538 16.966Z"
                                fill="#191919"/>
                            <path d="M84 31.9999H97.808V28.6479H84V31.9999Z" fill="#191919"/>
                        </svg>
                    </Link>
                    <div className='coin_footer_top_download'>
                        <Link
                            className='cta'
                            prefetch={false}
                            href={'/'}
                        >
                            <AppStoreButton/>
                        </Link>
                        <Link
                            className='cta'
                            prefetch={false}
                            href={'/'}
                        >
                            <PlayMarketButton/>
                        </Link>
                    </div>
                </section>
                <section className='coin_footer_bottom'>
                    {/*<p className='footer_bottom_copyright'>*/}
                    {/*    © Option2Trade {new Date().getFullYear()}. {footer.bottom.copy}*/}
                    {/*</p>*/}
                    <nav className='coin_footer_bottom_nav'>
                        <ul className='coin_footer_bottom_nav_list'>
                            {footer.bottom.links.map(item => {
                                return <BottomItem
                                    key={item.link}
                                    item={item}
                                />
                            })}
                        </ul>
                    </nav>
                    <Link
                        className='cta'
                        prefetch={false}
                        href={'mailto: support@option2tradepresale.com'}
                    >
                        Support@option2tradepresale.com
                    </Link>
                </section>
                <section className='coin_footer_disclaimer'>
                    <p className='coin_footer_disclaimer_text'>{footer.disclaimer}</p>
                    <ul className='coin_footer_disclaimer_social'>
                        {footer.bottom.social.map(item => {
                            return <li key={item.link} className='coin_footer_disclaimer_social_item'>
                                <Link
                                    className='social_item'
                                    href={item.url}
                                    target={'_blank'}
                                    aria-label={item.link}
                                >
                                    {item.link === 'telegram' ?
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                                                fill="#191919"/>
                                            <path
                                                d="M4.16184 9.91329L10.8959 7.13873C11.5607 6.84971 13.815 5.92486 13.815 5.92486C13.815 5.92486 14.8555 5.52023 14.7688 6.50289C14.7399 6.90751 14.5087 8.3237 14.2774 9.85549L13.5549 14.3931C13.5549 14.3931 13.4971 15.0578 13.0058 15.1734C12.5144 15.289 11.7052 14.7688 11.5607 14.6532C11.4451 14.5665 9.39305 13.2659 8.64161 12.6301C8.4393 12.4566 8.20808 12.1098 8.67051 11.7052C9.71097 10.7514 10.9537 9.56647 11.7052 8.81503C12.052 8.46821 12.3988 7.65896 10.9537 8.64162L6.8786 11.3873C6.8786 11.3873 6.41618 11.6763 5.54912 11.4162C4.68207 11.1561 3.67051 10.8092 3.67051 10.8092C3.67051 10.8092 2.97687 10.3757 4.16184 9.91329Z"
                                                fill="white"/>
                                        </svg> :
                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M19.422 1.20733C19.1383 2.15135 18.5577 2.86604 17.7469 3.41539C18.1349 3.39405 18.5068 3.30605 18.8788 3.20738C19.2534 3.10871 19.612 2.97004 20 2.81537C19.8662 3.04738 19.719 3.22872 19.5718 3.41005C19.133 3.9514 18.6353 4.43141 18.0706 4.84476C17.993 4.90076 17.9663 4.9621 17.969 5.05543C18.0305 8.18618 17.011 10.9356 14.9719 13.3037C13.8025 14.661 12.3629 15.6637 10.6877 16.3171C9.08215 16.9438 7.41236 17.1864 5.69173 17.1064C4.05673 17.0318 2.50736 16.6237 1.04094 15.9011C0.682366 15.7251 0.331817 15.5304 0 15.2984C1.07038 15.4024 2.12202 15.3197 3.15761 15.0344C4.19588 14.7464 5.13781 14.2744 6.02355 13.597C5.84426 13.5783 5.69976 13.5677 5.55793 13.5463C4.35644 13.365 3.40915 12.781 2.71341 11.789C2.51539 11.5063 2.36018 11.2023 2.24512 10.8796C2.19695 10.7436 2.20498 10.7329 2.34948 10.7569C2.86326 10.8449 3.37169 10.8183 3.87744 10.7063C3.9042 10.7009 3.92829 10.6956 3.95504 10.6876C3.9604 10.6849 3.96575 10.6743 3.97645 10.6583C3.83998 10.6183 3.70351 10.5809 3.56971 10.5382C1.94006 10.0102 0.767996 8.43419 0.75194 6.75148C0.749264 6.59414 0.749264 6.59414 0.893765 6.66614C1.37276 6.90882 1.87851 7.05015 2.4137 7.09549C2.44849 7.09816 2.48595 7.09815 2.52074 7.09815C2.52877 7.09815 2.53679 7.09015 2.54482 7.08482C2.54482 7.03415 2.49666 7.02615 2.46722 7.00482C0.816163 5.79679 0.305057 3.56206 1.27375 1.76201C1.34332 1.63134 1.346 1.63134 1.43698 1.74067C2.58229 3.09805 3.96307 4.14874 5.58202 4.88743C6.67648 5.38611 7.82446 5.70612 9.02061 5.84479C9.24539 5.87145 9.47284 5.88479 9.7003 5.90879C9.81001 5.92212 9.82606 5.88745 9.80466 5.78345C9.47552 4.11674 10.198 2.43136 11.627 1.52467C13.2539 0.495307 15.3572 0.711313 16.7353 2.06068C16.837 2.15935 16.9253 2.16202 17.0431 2.13535C17.8485 1.94868 18.6112 1.66067 19.3283 1.25533C19.3605 1.23933 19.3899 1.22333 19.422 1.20733Z"
                                                    fill="#191919"/>
                                            </svg>
                                    }
                                </Link>
                            </li>
                        })}
                    </ul>

                </section>
                <section className='coin_footer_copy'>
                    <h3 className="coin_footer_copy_title">{footer.copy.title}</h3>
                    <p className="coin_footer_copy_text">{footer.copy.description}</p>
                </section>
            </div>
        </footer>
    );
}

export default CoinFooter;