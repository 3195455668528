import React, {useState} from 'react';
import Link from "next/link";
import {useRouter} from "next/router";

const AccordionItem = ({toggleMenu, item, index, activeIndex, setActiveIndex}) => {
    const router = useRouter();

    return <li
        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay="0"
    >
        <div
            onClick={(e) => {
                if (!activeIndex || activeIndex !== index) {
                    setActiveIndex(index)
                } else {
                    setActiveIndex(null)
                }
            }}
            className={`accordion_item_wrapper ${activeIndex === index ? 'is_active' : ''}`}
        >
            <div className='accordion_item'>
                <h3 className='accordion_item_title'>{item.title}</h3>
                <span className={`accordion_item_icon ${activeIndex === index ? 'open' : 'close'}`}>
                    <span className='accordion_item_icon_locale'>{router.locale}</span>
                    <span className='accordion_item_icon_symbol'>+</span>
                </span>
            </div>
            <div className={`accordion_item_body`}>

                    <ol>
                        {router.locales.map((locale) => (

                            <li key={locale}>
                                <Link
                                    onClick={toggleMenu}
                                    href={router.asPath} locale={locale}
                                >
                                    <span className='href_lang'>
                                        <div
                                            className='lang_hidden_item'
                                        >
                                                 <div
                                                     className='lang_hidden_item_flag'
                                                 >
                                        <img
                                            src={`/img/flags/${locale}.svg`}
                                            alt={`lang ${item.locales[locale]}`}
                                        />
                                    </div>
                                            <span>{item.locales[locale]}</span>
                                        </div>
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ol>
            </div>
        </div>
    </li>
}

const Accordion = ({nav_list, toggleMenu}) => {

    const [activeIndex, setActiveIndex] = useState(null)

    return (
        <div className='accordion_container'>
            <ul className='accordion_data'>
                {nav_list.map((item) => {
                    return <AccordionItem
                        toggleMenu={toggleMenu}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        index={item.key}
                        key={item.key}
                        item={item}
                    />
                })}
            </ul>
        </div>
    );
};

export default Accordion;