import React from 'react';

const AppStoreButton = () => {
    return (
        <button className='app_store_button store_button'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23" fill="none">
                <path d="M16 12.303C16.0113 11.4285 16.2436 10.5711 16.6753 9.81057C17.107 9.05001 17.724 8.41099 18.469 7.95297C17.9958 7.27702 17.3716 6.72071 16.6458 6.32822C15.92 5.93573 15.1127 5.71788 14.288 5.69197C12.526 5.50997 10.826 6.74797 9.92599 6.74797C9.01199 6.74797 7.63199 5.71397 6.14499 5.74797C5.18406 5.77897 4.24752 6.05821 3.42652 6.55852C2.60553 7.05883 1.92803 7.76316 1.45999 8.60297C-0.56601 12.111 0.94499 17.267 2.88599 20.103C3.85699 21.492 4.99199 23.043 6.47699 22.988C7.92999 22.928 8.47699 22.061 10.227 22.061C11.965 22.061 12.474 22.988 13.989 22.953C15.548 22.928 16.531 21.558 17.468 20.153C18.1658 19.1636 18.7027 18.0701 19.059 16.913C18.1528 16.5298 17.3794 15.8883 16.8354 15.0685C16.2914 14.2487 16.0009 13.2869 16 12.303Z" fill="#191919"/>
                <path d="M13.139 3.82997C13.9898 2.80963 14.4088 1.49757 14.307 0.172974C13.0082 0.309612 11.8085 0.930506 10.947 1.91197C10.5253 2.39135 10.2023 2.94917 9.99635 3.55351C9.79044 4.15785 9.7057 4.79685 9.74698 5.43397C10.3964 5.44109 11.039 5.30079 11.6263 5.02365C12.2137 4.74651 12.7306 4.33975 13.138 3.83397L13.139 3.82997Z" fill="#191919"/>
            </svg>
            <div className='store_button_text'>
                <span className='text'>Download on the</span>
                <span className='store'>App Store</span>
            </div>
        </button>
    );
};

export default AppStoreButton;