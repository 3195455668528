import React from 'react';

const Loader = ({isLoading, loaded}) => {
    return (
        <div className={`page_loader ${isLoading ? 'loading' : ''} ${loaded ? 'loaded' : ''}`}>
            <div className="page_loader_content">
                <svg xmlns="http://www.w3.org/2000/svg" width="132" height="20" viewBox="0 0 132 20" fill="none">
                    <path d="M54.2852 14.5345V4.30338H57.3098V5.58226C57.7361 4.68907 58.8729 4.08008 60.2939 4.08008C62.8111 4.08008 64.2117 5.68376 64.2117 8.32273V14.5345H61.1871V8.68813C61.1871 7.51074 60.5375 6.75965 59.3195 6.75965C58.1624 6.75965 57.3098 7.59194 57.3098 8.78963V14.5345H54.2852Z" fill="#191919"/>
                    <path d="M41.3301 9.41907C41.3301 6.3944 43.8472 3.99902 47.014 3.99902C50.1605 3.99902 52.698 6.3944 52.698 9.41907C52.698 12.4437 50.1605 14.8391 47.014 14.8391C43.8472 14.8391 41.3301 12.4437 41.3301 9.41907ZM44.3547 9.41907C44.3547 10.9212 45.5118 12.0986 47.014 12.0986C48.4959 12.0986 49.6733 10.9212 49.6733 9.41907C49.6733 7.93718 48.4959 6.73949 47.014 6.73949C45.5118 6.73949 44.3547 7.93718 44.3547 9.41907Z" fill="#191919"/>
                    <path d="M38.2235 3.43066C37.2897 3.43066 36.498 2.67957 36.498 1.72548C36.498 0.751092 37.2897 0 38.2235 0C39.1776 0 39.949 0.751092 39.949 1.72548C39.949 2.67957 39.1776 3.43066 38.2235 3.43066ZM36.7213 14.5346V4.30355H39.746V14.5346H36.7213Z" fill="#191919"/>
                    <path d="M30.2146 14.5347V7.04407H28.8545V4.3036H30.2146V0.385742H33.158V4.3036H35.4113V7.04407H33.158V14.5347H30.2146Z" fill="#191919"/>
                    <path d="M16.8379 18.4522V4.3033H19.8626V5.64309C20.1468 4.7702 21.3444 4.12061 22.9075 4.12061C25.8104 4.12061 27.9825 6.45508 27.9825 9.41885C27.9825 12.3623 25.8104 14.7171 22.9075 14.7171C21.3444 14.7171 20.1468 14.0878 19.8626 13.1946V18.4522H16.8379ZM19.6799 9.41885C19.6799 10.8601 20.8775 11.9766 22.3594 11.9766C23.821 11.9766 24.9578 10.8601 24.9578 9.41885C24.9578 7.97756 23.821 6.86108 22.3594 6.86108C20.8775 6.86108 19.6799 7.97756 19.6799 9.41885Z" fill="#191919"/>
                    <path d="M0 7.49082C0 3.39026 3.36976 0.162598 7.55152 0.162598C11.7536 0.162598 15.103 3.39026 15.103 7.49082C15.103 11.6117 11.7536 14.819 7.55152 14.819C3.36976 14.819 0 11.6117 0 7.49082ZM3.12617 7.49082C3.12617 10.0283 5.05464 11.9771 7.55152 11.9771C10.0281 11.9771 11.9769 9.98769 11.9769 7.49082C11.9769 5.03454 10.0484 3.00457 7.55152 3.00457C5.05464 3.00457 3.12617 5.01424 3.12617 7.49082Z" fill="#191919"/>
                    <path d="M64.7314 14.5348L71.3086 6.43523C71.7552 5.88714 72.0597 5.31874 72.0597 4.68945C72.0597 3.75566 71.3492 3.00457 70.4357 3.00457C69.4613 3.00457 68.6696 3.93836 68.6696 5.09544H65.5434C65.5434 2.35497 67.7358 0.162598 70.4357 0.162598C73.0544 0.162598 75.1858 2.19257 75.1858 4.68945C75.1858 5.46084 75.1046 6.47583 74.0896 7.69382L70.7808 11.6929H75.5918V14.5348H64.7314Z" fill="#191919"/>
                    <path d="M120.806 9.5004V9.3989C120.806 6.15094 123.12 4.06006 126.226 4.06006C129.352 4.06006 131.402 6.37423 131.402 9.2365C131.402 9.2365 131.402 9.7643 131.341 10.2312H123.83C123.891 11.4492 124.927 12.2206 126.53 12.2206C128.479 12.2206 129.575 11.6319 130.164 11.2259V13.8649C129.21 14.4738 128.154 14.7986 126.368 14.7986C123.059 14.7986 120.806 12.7078 120.806 9.5004ZM123.851 8.26211H128.357C128.357 7.34862 127.423 6.57723 126.226 6.57723C124.967 6.57723 123.912 7.32832 123.851 8.26211Z" fill="#191919"/>
                    <path d="M108.099 9.41914C108.099 6.47568 110.271 4.1209 113.174 4.1209C114.737 4.1209 115.934 4.7502 116.219 5.64339V0.385742H119.243V14.5347H116.219V13.1949C115.934 14.0678 114.737 14.7174 113.174 14.7174C110.271 14.7174 108.099 12.3829 108.099 9.41914ZM111.123 9.41914C111.123 10.8604 112.26 11.9769 113.722 11.9769C115.204 11.9769 116.401 10.8604 116.401 9.41914C116.401 7.97786 115.204 6.86137 113.722 6.86137C112.26 6.86137 111.123 7.97786 111.123 9.41914Z" fill="#191919"/>
                    <path d="M95.3916 9.41934C95.3916 6.47587 97.5637 4.12109 100.467 4.12109C102.03 4.12109 103.227 4.75039 103.512 5.64358V4.30379H106.536V14.5349H103.512V13.1951C103.227 14.068 102.03 14.7176 100.467 14.7176C97.5637 14.7176 95.3916 12.3831 95.3916 9.41934ZM98.4163 9.41934C98.4163 10.8606 99.5531 11.9771 101.015 11.9771C102.497 11.9771 103.694 10.8606 103.694 9.41934C103.694 7.97805 102.497 6.86156 101.015 6.86156C99.5531 6.86156 98.4163 7.97805 98.4163 9.41934Z" fill="#191919"/>
                    <path d="M88.0469 14.5345V4.30338H91.0715V5.70406C91.5384 4.66877 92.6346 4.08008 94.0353 4.08008C94.3195 4.08008 94.6646 4.12068 94.827 4.14098V6.98295C94.5428 6.92205 94.1977 6.88145 93.812 6.88145C92.0865 6.88145 91.0715 7.87614 91.0715 9.54072V14.5345H88.0469Z" fill="#191919"/>
                    <path d="M80.6853 14.5347V3.22771H76.8486V0.385742H87.5872V3.22771H83.8115V14.5347H80.6853Z" fill="#191919"/>
                    <path d="M64.874 19.9999H75.6548V17.3828H64.874V19.9999Z" fill="#191919"/>
                </svg>
            </div>
        </div>
    );
};

export default Loader;