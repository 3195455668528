import React from 'react';

const PlayMarketButton = () => {
    return (
        <button className='play_market_button store_button'>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                <path d="M11.806 10.398L2.08902 0.670044H2.18302C3.14789 0.740309 4.07811 1.05934 4.88302 1.59604L15.077 7.11004L11.806 10.398ZM10.861 11.341L0.879015 21.3C0.568002 20.6938 0.425701 20.0151 0.467015 19.335V3.33504C0.426626 2.65468 0.569965 1.97597 0.882015 1.37004L10.861 11.341ZM11.806 12.269L15.077 15.556L4.88602 21.07C4.08109 21.6069 3.15093 21.9262 2.18602 21.997H2.08801L11.805 12.27L11.806 12.269ZM16.306 7.76904L12.752 11.34L16.306 14.893L18.461 13.721C19.255 13.286 20.461 12.493 20.461 11.341C20.461 10.169 19.251 9.37604 18.461 8.94104L16.302 7.77004L16.306 7.76904Z" fill="#191919"/>
            </svg>
            <div className='store_button_text'>
                <span className='text'>GET IT ON</span>
                <span className='store'>Google Play</span>
            </div>
        </button>
    );
};

export default PlayMarketButton;